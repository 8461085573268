import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";



const InfoToolTip: React.FC<{ id: string, ayuda: string, placement?:Placement, className?: string }> = ({ id, ayuda, placement="left", className }) => {
    return (
        <OverlayTrigger key={id} placement={placement} overlay={<Tooltip id={`tooltip-${id}`}>{ayuda} </Tooltip>}>
            <FontAwesomeIcon className={className}  icon={faQuestionCircle} style={{ height: "1em", width:"auto", display:"inline"}} />
        </OverlayTrigger>)
}

export default InfoToolTip