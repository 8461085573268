import React, { useEffect, useState } from 'react';
import logo from './logo.svg';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { useHistory } from 'react-router';
import { Jumbotron } from 'react-bootstrap';

const firebaseConfig = {
  apiKey: "AIzaSyBbBvzEkABogAs2w0T7vOm0yI66oO89JQs",
  authDomain: "simplypro-a5720.firebaseapp.com",
  databaseURL: "https://simplypro-a5720.firebaseio.com",
  projectId: "simplypro-a5720",
  storageBucket: "simplypro-a5720.appspot.com",
  messagingSenderId: "369790194864",
  appId: "1:369790194864:web:ba83075e54310141a3d775"
};


firebase.initializeApp(firebaseConfig);



// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};

const Ingresar: React.FC = (props: any) => {

  const history = useHistory();

  useEffect(() => {

    const currentUser = firebase.auth().currentUser;
    if(!!currentUser){
      history.push("/declaraciones");
    }
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        if(!!user){
          history.push("/declaraciones");
        }
      }
    )

    return () => {
      unregisterAuthObserver();
    }

})

return (
  <Jumbotron>

    <h3 className="text-center">Ingresa o Registrate</h3>
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  </Jumbotron>
);
  
}

export default Ingresar;
