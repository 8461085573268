import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, CardDeck } from 'react-bootstrap';
import { DeclaracionDeRenta } from '../dbTypes/Declaracione';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection } from 'react-firebase-hooks/firestore'
import firebase from 'firebase';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Declaracion from './declaracion/Declaracion';
import Preparacion from './preparacion/Preparación';

const Declaraciones: React.FC = (props: any) => {

    //const [declaraciones, setDeclaraciones] = useState<DeclaracionDeRenta[]>();

    const [user, loadingAuth, errorAuth] = useAuthState(firebase.auth());
    const [declaraciones, loadingDeclaraciones, errorDeclaraciones] = useCollection(firebase.firestore().collection(`/usuarios/${user?.uid}/declaraciones`));
    const { path, url } = useRouteMatch();

    const getButton = (declaracion: DeclaracionDeRenta, id: string) => {
         
        return (
        <LinkContainer to={`${url}/pre/${id}`}>
            <Button variant="primary">Continuar</Button>
        </LinkContainer>
        );
        // return declaracion.preparacionCompletada ?
        //     (
        //         <LinkContainer to={`${url}/${id}`}>
        //             <Button variant="primary">Continuar</Button>
        //         </LinkContainer>):
        //     (
        //         <LinkContainer to={`${url}/pre/${id}`}>
        //             <Button variant="primary">Comenzar</Button>
        //         </LinkContainer>)
    }


    return (
        <Switch>


            <Route exact path={path}>
                <h1>Mis Declaraciones</h1>
                <CardDeck>
                    {errorDeclaraciones && <strong>Error: {JSON.stringify(errorDeclaraciones)}</strong>}
                    {loadingDeclaraciones && <span>Collection: Loading...</span>}
                    {declaraciones && declaraciones.docs.map(doc => {
                        const declaracion = doc.data() as DeclaracionDeRenta;
                        return (<Card key={declaracion.uid}>
                            <Card.Header>{declaracion.titulo}</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {declaracion.estado}
                                </Card.Text>

                                {getButton(declaracion, doc.id)}

                            </Card.Body>
                        </Card>);
                    })
                    }

                </CardDeck>
            </Route>


            <Route path={`${path}/pre/:declaracionId`}>
                <Preparacion></Preparacion>

            </Route>

            <Route path={`${path}/:declaracionId/:seccionKey?`}>
                <Declaracion></Declaracion>
            </Route>
        </Switch>
    )
};

export default Declaraciones;