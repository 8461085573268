import Declaraciones from "../Declaraciones"
import React, { useState } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { useCollectionDataOnce, useCollectionOnce, useDocument, useDocumentData } from 'react-firebase-hooks/firestore';
import firebase from "firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import SideBar from "./Sidebar";
import { Button, Col, Row } from "react-bootstrap";
import NavBar from "./Navbar";
//import Formulario from "../widgets/Formulario"
import { DeclaracionDeRenta } from "../../dbTypes/Declaracione";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Finalizar from "./Pasos/Finalizar";
import Plantilla from "../widgets/Plantilla";
import { SeccionUI, UISectionDefinition } from "simplypro-db";
import Calculos from "./Calculos";
interface RouterParams {
    declaracionId: string,
    seccionKey:string
}

const Declaracion: React.FC = (props: any) => {

    const { declaracionId, seccionKey="perfil" } = useParams<RouterParams>();
    const [user] = useAuthState(firebase.auth());

    const [declaracion, loading] = useDocumentData<any>(
        firebase.firestore().collection(`/usuarios/${user?.uid}/declaraciones`).doc(declaracionId)
    );

    const [seccionesUI, loadingDefinition, errorDefinition] = useCollectionDataOnce<UISectionDefinition>(firebase.firestore().collection(`/definiciones/${declaracionId}/seccionesUI`).orderBy("indice", "asc"));

    const [isOpen, setOpen] = useState<boolean>(true)
    const [labelsVisible, setLabelsVisible] = useState<boolean>(true)
    const [expanded, setexpanded] = useState<boolean>(true)
    const history = useHistory()

    const toggle = () => {
        const newState = !isOpen;
        const timeout = newState ? 500 : 0;
        const timeout2 = newState ? 0 : 500;
        setOpen(newState);
        setTimeout(() => { setLabelsVisible(newState); }, timeout);
        setTimeout(() => { setexpanded(newState); }, timeout2);
    };

    const getSectionRoutes = (declaracionId: string ,seccionesUI: UISectionDefinition[] )=>{
        const result =  seccionesUI.map(s=>{
            return (<Route key={s.id} exact path={`/declaraciones/${declaracionId}/${s.id}`} render={ ()=> <Plantilla declaracionId={declaracionId} seccionUI={s.id} />} />)
        });

        result.push(
        <Route key={declaracionId} exact path={`/declaraciones/${declaracionId}/`} >
            <Redirect to={`/declaraciones/${declaracionId}/${seccionesUI[0].id}`} />
        </Route>
        )


        result.push(
            <Route key={"calculos"}  exact path={`/declaraciones/${declaracionId}/calculos`}  render={ ()=> <Calculos declaracionId={declaracionId}  />} />
            )


        return result;
    }

    const continuar = ()=> {
        if(seccionesUI){
            const currentSectionIndex = seccionesUI?.findIndex(s=> s.id === seccionKey);
            if(currentSectionIndex !== undefined){
             const nextSectionIndex = currentSectionIndex +1;
             history.push(`/declaraciones/${declaracionId}/${seccionesUI[nextSectionIndex].id}`)
            }
        }
    }


    const contunarActivado = declaracion && declaracion[seccionKey] === "completado";


    return (
        <>
            <Row>
                {declaracion &&
                <Col>
                    <NavBar toggle={toggle} title={declaracion.titulo} />

                </Col>
                }

            </Row>
            <Row>
                {seccionesUI && declaracion &&
                <Col md={expanded ? 3 : 1}>
                    <SideBar declaracionId={declaracionId} toggle={toggle} isOpen={isOpen} labelsVisible={labelsVisible} declaracion={declaracion} seccionKey={seccionKey} seccionesUI={seccionesUI}/>
                </Col>
                }


                {!loading && declaracion && seccionesUI && (<Col md={expanded ? 9 : 11}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <Switch>
                            {getSectionRoutes(declaracionId, seccionesUI)}
                        </Switch>
                        <Button onClick={continuar} disabled={!contunarActivado}>Continuar</Button>
                    </div>
                </Col>)}




            </Row>
        </>
    )

}

export default Declaracion;