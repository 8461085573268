import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "react-bootstrap";
import { MouseEventHandler } from "react-transition-group/node_modules/@types/react";

const  NavBar :React.FC<{toggle:MouseEventHandler<HTMLElement>, title:string}> = ({toggle, title}) => {

    return (
      <div
        className="d-flex justify-content-start"
        style={{padding:"8px"}}
      >
        <Button variant="outline-info" onClick={toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>

        <h3 style={{marginLeft:"30px"}}>{title} </h3>

      </div>
    );
  
}

export default NavBar;