"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.esPasoAPaso = exports.esTablaEstatica = exports.esPreguntaNumero = exports.esPreguntaOpcion = exports.esPreguntaSiNo = exports.esPreguntaTextoLibre = exports.esPreguntaMultipleNumerica = exports.esPreguntaSimple = void 0;
function esTipoDePregunta(pregunta, tipo) {
    return tipo === pregunta.tipo;
}
function esPreguntaSimple(p) {
    return esPreguntaNumero(p) || esPreguntaOpcion(p) || esPreguntaSiNo(p) || esPreguntaTextoLibre(p) || esPreguntaMultipleNumerica(p);
}
exports.esPreguntaSimple = esPreguntaSimple;
function esPreguntaMultipleNumerica(p) {
    return esTipoDePregunta(p, "número-múltiple");
}
exports.esPreguntaMultipleNumerica = esPreguntaMultipleNumerica;
function esPreguntaTextoLibre(p) {
    return esTipoDePregunta(p, "texto-libre");
}
exports.esPreguntaTextoLibre = esPreguntaTextoLibre;
function esPreguntaSiNo(p) {
    return esTipoDePregunta(p, "si-no");
}
exports.esPreguntaSiNo = esPreguntaSiNo;
function esPreguntaOpcion(p) {
    return p.tipo === "opción-única" || p.tipo === "opción-múltiple";
}
exports.esPreguntaOpcion = esPreguntaOpcion;
function esPreguntaNumero(p) {
    return p.tipo === "número" || p.tipo === "si-no-número";
}
exports.esPreguntaNumero = esPreguntaNumero;
function esTablaEstatica(p) {
    return esTipoDePregunta(p, "tabla-estática");
}
exports.esTablaEstatica = esTablaEstatica;
function esPasoAPaso(p) {
    return esTipoDePregunta(p, "paso-a-paso");
}
exports.esPasoAPaso = esPasoAPaso;
