import firebase, { FirebaseError, firestore } from "firebase";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useCollectionData } from "react-firebase-hooks/firestore";
import { esPreguntaOpcion, esPreguntaSimple, esPreguntaSiNo, esPreguntaTextoLibre, 
    Pregunta, PreguntaOpcion, PreguntaSimple, PreguntaSiNo, Respuesta, 
    RespuestaOpcionMultiple, RespuestaOpcionUnica, 
    RespuestaSiNo, SeccionUI, PreguntaTextoLibre, RespuestaTextoLibre, esPreguntaMultipleNumerica, RespuestanNumeroMultiple, esPreguntaNumero, PreguntaNumero, RespuestaSiNoNumero, PreguntaMultipleValorNumerico, esTablaEstatica, TablaEstatica, ValorOpcionMultiple, Condicion, CondicionDePregunta  } from "simplypro-db";
import { ReferenceEntry } from "typescript";
import InfoToolTip from "./InfoTooltip";
import NumericoMultiple, { NumericoMultipleValue } from "./NumericoMultiple";
import OpcionMultiple, { OnSelectionChanged } from "./OpcionMultiple";
import OpcionUnica from "./OpcionUnica";
import SiNo from "./SiNo";
import SiNoNumero, { SiNoNumeroValue } from "./SiNoNumero";
import Tabla from "./Tabla";
import TextoLibre from "./TextoLibre";

type DocumenDataQuerySnap = firestore.QuerySnapshot<firestore.DocumentData>;
type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement|HTMLSelectElement;
type PreguntaConVisibilidad = {mostrar:boolean, pregunta: Pregunta & firebase.firestore.DocumentData}

type some = string | number | boolean | object;

function hasValue(x:any|null): x is some {
    
    if (typeof x === 'undefined') {
        return false;
    } else if ( x === null) {
        return false;
    } else {
        return true;
    }
}

const Plantilla: React.FC<{ declaracionId: string, seccionUI: SeccionUI }> = ({ declaracionId, seccionUI }) => {
    const [user, loadingAuth, errorAuth] = useAuthState(firebase.auth());
    const [preguntas, cargandoPreguntas, errorCargandoPreguntas] =  useCollection(firebase.firestore().collection(`/definiciones/${declaracionId}/preguntas`).where('seccionUI', '==', `${seccionUI}`).orderBy("indice"));
    const [respuestas, cargandoRespuestas, errorCargandoRespuestas] =  useCollection(firebase.firestore().collection(`/usuarios/${user?.uid}/declaraciones/${declaracionId}/respuestas/`));
    const [preguntasConVisibilidad, setPreguntasConVisibilidad]  = useState<PreguntaConVisibilidad[]>()

    useEffect(() => {

        if(preguntas && respuestas ){
            Promise.all( preguntas.docs.map(async doc=>{
                //useCollection(firebase.firestore().collection()
                const pregunta = doc.data()
                const consdicionales = (await doc.ref.collection("condicionales").get()).docs
                const opciones = (await doc.ref.collection("opciones").get()).docs
                if(consdicionales){
    
                    const condicionesObligatorias = consdicionales.map(c=> c.data()).filter(c=> c.obligatorio);
                    const condicionesNoObligatorias = consdicionales.map(c=> c.data()).filter(c=> !c.obligatorio);
    
                    const seCumple = (condicion: firestore.DocumentData) => {
                        let preguntaIdProp :any = condicion.preguntaId;
                        let preguntaId;
                        if(typeof  preguntaIdProp === "string"){
                            const n = preguntaIdProp.lastIndexOf('/');
                            preguntaId = preguntaIdProp.substring(n + 1);
                        } else  {
                            const t =  typeof  preguntaIdProp;
                            preguntaId = preguntaIdProp.id
                        }
    
                        const respuesta = getRespuestaById(preguntaId, respuestas);
                        let condicionCumplida = false;

                        const valor = respuesta?.valor;

                        switch(condicion.operacion){
                            case ">":
                                condicionCumplida = (hasValue(valor)  && valor > condicion.valor_numerico);
                                break;
                            case "<":
                                condicionCumplida = (hasValue(valor)  && valor < condicion.valor_numerico);
                                break;
                            case "=":
                                condicionCumplida = (hasValue(valor)  &&  (valor === condicion.valor_numerico || valor === condicion.valor_boolean ));
                                break;
                            case "incluye":
                                if(hasValue(valor) ){
                                    if(valor instanceof Object ){
                                        const valor2 = valor as ValorOpcionMultiple
                                        const valoresRespuesta = valor2.seleccion  as string[];
                                        const valoresCondicion = condicion.valor_opcion as firebase.firestore.DocumentReference[];
                                        condicionCumplida = valoresCondicion.filter(vc=> valoresRespuesta.includes(vc.id)).length>0;
                                    }
                                    
                                }
                                
                                
                                break;    
                        }
                        if(condicion.negación){
                            condicionCumplida = ! condicionCumplida;
                        }
    
                        return condicionCumplida
    
                    };
    
                    const noObligatoriasCumplidas = condicionesNoObligatorias.length < 1 ? true : (await Promise.all(condicionesNoObligatorias.map(seCumple))).reduce((acc,value)=> acc || value, false);
                    const obligatoriasCumplidas = (await Promise.all(condicionesObligatorias.map(seCumple))).reduce((acc,value)=> acc && value, true) ;
    
    
    
                    const todasLasCondicionesCumplidas = noObligatoriasCumplidas && obligatoriasCumplidas;
                    if(opciones){
                        pregunta.opciones = opciones.map(o=> o.data())
                    }
    
                    return {mostrar:todasLasCondicionesCumplidas, pregunta: pregunta as Pregunta}
                }

                if(opciones){
                    pregunta.opciones = opciones.map(o=> o.data())
                }
    
            return { mostrar: true, pregunta: pregunta as Pregunta & firebase.firestore.DocumentData};
            })).then(preguntasConVisibilidad=>{
                    //SideEfect calculate if section is complete
                    setPreguntasConVisibilidad(preguntasConVisibilidad)
                    const  preguntasVisiblesSinRespuesta = preguntasConVisibilidad.filter(p=>p.mostrar).filter(p=> getRespuestaById(p.pregunta.id, respuestas)?.valor === undefined);
                    const update ={} as any;
                    update[`${seccionUI}`] = preguntasVisiblesSinRespuesta.length>0 ? "comenzado": "completado";
                    firebase.firestore().doc(`/usuarios/${user?.uid}/declaraciones/${declaracionId}/`).update(update);
            });


    



        }
       


    },[preguntas, respuestas, declaracionId, seccionUI, user] )


    const getRespuestaWithSnapForPregunta = <R extends Respuesta>(p:Pregunta, respuestas: DocumenDataQuerySnap) =>{
        const snapValueMap = respuestas.docs.map(d=> ({respuestaSnap: d , respuesta:(d.data() as R)}));
        const result =  snapValueMap.find(({respuestaSnap,respuesta}) =>{
            return respuesta.id === p.id;
        });

        if(!result){
            throw Error("Respuesta no encontrada");
        }
        return result;
    };

    const getRespuestaById = (id:String, respuestas : DocumenDataQuerySnap) => {
        return respuestas.docs.find( r => (r.data()as Respuesta).id == id )?.data() as Respuesta | undefined;
    }

    const campoSiNo = (pregunta:PreguntaSiNo, respuestas: DocumenDataQuerySnap)=>{
        const {respuestaSnap,respuesta} = getRespuestaWithSnapForPregunta<RespuestaSiNo>(pregunta, respuestas);
        const onSiNoChanged =  (v: boolean | undefined) => {
                respuesta.valor = v;
                respuestaSnap.ref.set(respuesta);
        }
        
        return <SiNo value={respuesta?.valor} onValueChanged={onSiNoChanged}  />
    }

    const campoSiNoNúmero = (pregunta:PreguntaNumero, respuestas: DocumenDataQuerySnap)=>{
        const {respuestaSnap,respuesta} = getRespuestaWithSnapForPregunta<RespuestaSiNoNumero>(pregunta, respuestas);
        const onSiNoChanged =  (v:SiNoNumeroValue) => {
                respuesta.valor = {siNo: v.siNo !== undefined? v.siNo: false, numero:v.numero};
                respuestaSnap.ref.set(respuesta);
        }
        
        return <SiNoNumero value={respuesta?.valor} onValueChanged={onSiNoChanged} />
    }

    const campoTextoLibre = (pregunta:PreguntaTextoLibre, respuestas: DocumenDataQuerySnap)=>{
        const {respuestaSnap, respuesta} = getRespuestaWithSnapForPregunta<RespuestaTextoLibre>(pregunta, respuestas);
        const onTextChanges = (newValue: string)=>{
            respuesta.valor = newValue;
            respuestaSnap.ref.set(respuesta);
        }
        
        return <TextoLibre value={respuesta?.valor} onValueChanged={onTextChanges}  />
    }

    const numericoMultiple = (pregunta:PreguntaMultipleValorNumerico, respuestas: DocumenDataQuerySnap, className:string)=>{
        const {respuestaSnap, respuesta} = getRespuestaWithSnapForPregunta<RespuestanNumeroMultiple>(pregunta, respuestas);
        const onTextChanges = (newValue: NumericoMultipleValue|undefined)=>{
            if(respuesta && newValue){

                respuestaSnap.ref.set(newValue);
            }

        }
        


        return <NumericoMultiple value={respuesta} pregunta={pregunta} onValueChanged={onTextChanges} className={className} />
    }

    const getTablaEstatica = (pregunta:TablaEstatica<"número">, respuestas: DocumenDataQuerySnap, className:string)=>{
        return <Tabla pregunta={pregunta} className={className} declaracionId={declaracionId} userId={user?.uid} />
    }

    const  getCampoOpcionUnica= (pregunta: PreguntaOpcion, respuestas: DocumenDataQuerySnap) => {
        const {respuestaSnap,respuesta} = getRespuestaWithSnapForPregunta<RespuestaOpcionUnica>(pregunta, respuestas);
        const onInputFieldChange =  (event: React.ChangeEvent<FormControlElement>) => {
                respuesta.valor = event.currentTarget.value;
                respuestaSnap.ref.set(respuesta);
            }
        
        return <OpcionUnica valor={respuesta.valor} onValueChanged={onInputFieldChange} opciones={pregunta.opciones} />
    }

    const  getCampoOpcionMultiple= (pregunta: PreguntaOpcion, respuestas: DocumenDataQuerySnap) => {
        const {respuestaSnap,respuesta} = getRespuestaWithSnapForPregunta<RespuestaOpcionMultiple>(pregunta, respuestas);
        const onInputFieldChange : OnSelectionChanged = (seleccion: string[], valores: {[key:string] : any}|null) => {
                respuesta.valor = {
                    seleccion: seleccion||[],
                    valores: valores||{}

                };
                respuestaSnap.ref.set(respuesta);
            }
        
        return <OpcionMultiple valor={respuesta.valor} onSelectionChanged={onInputFieldChange} opciones={pregunta.opciones} tieneValores={pregunta.tieneValores||false} />
    }

    const getInputField = (pregunta: PreguntaSimple, respuestas: DocumenDataQuerySnap) =>{


        if(esPreguntaSiNo(pregunta)){
            return campoSiNo(pregunta, respuestas);
        }

        if(esPreguntaNumero(pregunta)){
            return campoSiNoNúmero(pregunta, respuestas);

        }
        if(esPreguntaOpcion(pregunta)){
            switch(pregunta.tipo){
                case "opción-única":
                    return getCampoOpcionUnica(pregunta,respuestas);
                case "opción-múltiple":
                    return getCampoOpcionMultiple(pregunta,respuestas);
                    
            }
        }



        if(esPreguntaTextoLibre(pregunta)){
            return campoTextoLibre(pregunta, respuestas)
        }
    }


    const getFormFields =  (preguntasConVisibilidad: PreguntaConVisibilidad[], respuestas: DocumenDataQuerySnap) =>{


        return preguntasConVisibilidad.map(({mostrar, pregunta})=>{
            let className ="";
            if(!mostrar){
                className += "preguntaEscondida";
            }

            return(
                <React.Fragment  key={pregunta.id}>
            {esPreguntaMultipleNumerica(pregunta)? 
                numericoMultiple(pregunta,  respuestas, className)
                :
                esTablaEstatica(pregunta) ?
                (getTablaEstatica(pregunta,  respuestas, className))

                :
                <Form.Group  as={Row} controlId={pregunta.id} className={className}>
                    <Form.Label column sm="6" >
                        {pregunta.texto}
                    </Form.Label>
                    <Col sm="6">
                        <div className="d-flex justify-content-end" >
                            
                        
                        {getInputField(pregunta as PreguntaSimple, respuestas)}
                        &nbsp;
                        <InfoToolTip id={pregunta.id} ayuda={pregunta.ayuda}/>
                        </div>
                    </Col>
                </Form.Group>

            }
            

            </ React.Fragment >)
        })

                
    }

    return (
    <div>
        {(cargandoPreguntas || cargandoRespuestas) && "Cargando..."}
        {preguntasConVisibilidad && respuestas &&
            <Form>
                {getFormFields(preguntasConVisibilidad, respuestas)}

            </Form>
        }

        {errorCargandoPreguntas && errorCargandoPreguntas.message}
    </div>)
}

export default Plantilla;




