import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams, Redirect
} from "react-router-dom";
import Ingresar from './components/Ingresar';
import Inicio from './components/Inicio'
import { Container, Jumbotron } from 'react-bootstrap';
import Navigation from './components/Navigation';
import Declaraciones from './components/Declaraciones';
import Herramientas from './components/Herramientas';
import {Usuario} from './dbTypes/Usuario'
import 'holderjs';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// fontawesome
import initFontAwesome from "./utils/initFontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'



initFontAwesome();
library.add(fas)




const App: React.FC = (props: any) => {

  return (
    <Router>
      <Navigation />
      <Container className="p-3">
          <Switch>


            <Route path="/inicio">
              <Inicio />
            </Route>
            <Route path="/ingresar">
              <Ingresar />
            </Route>
            <Route path="/declaraciones">
              <Declaraciones />
            </Route>
            <Route path="/herramientas">
              <Herramientas />
            </Route>


            <Route exact path="/">
              <Redirect to="/inicio" />
            </Route>

          </Switch>

      </Container>

    </Router>
  );

}

export default App;
