import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { NavLink, useHistory } from "react-router-dom";
import { Button, Collapse, Container, Dropdown, Form, Modal, ModalFooter, Nav, Navbar, NavbarBrand, NavDropdown, NavItem } from "react-bootstrap"
import logo from '../assets/logo.png';
import { LinkContainer } from 'react-router-bootstrap';
import firebase from "firebase";
import { Usuario } from "../dbTypes/Usuario";

const Navigation: React.FC = (props: any) => {

  const [isOpen, setIsOpen] = useState(false);

  const [isAuthenticated, setAuthenticated] = useState(!!firebase.auth().currentUser);
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string|null>()

  const [usuario, setUsuario] = useState<Usuario>();


  const toggle = () => setIsOpen(!isOpen);

  const user = { name: "Name", picture: "user.png" }
  const history = useHistory();

  useEffect(() => {

    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async (user) => {
        setAuthenticated(!!user)
  
        if (user) {
          if(user.displayName){
            setUsername(user.displayName);
            setEmail(user.email)
          }
        }
      }
    )

    return () => {
      unregisterAuthObserver();
    }

  },[user])

  const login = () => {
    history.push("ingresar")
  }

  const logout = () => {
    firebase.auth().signOut().then(() => {
      history.push("inicio");
    });

  }



  return (
    <>
      <Navbar color="light" expand="md">
        <Container>
          <LinkContainer to="/inicio">
            <Navbar.Brand  > <img src={logo} height='55px'></img></Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle onClick={toggle} />
          <Navbar.Collapse>

            <Nav className="mr-auto" navbar>
              {isAuthenticated && (
                <Nav.Item>
                  <LinkContainer to="/declaraciones">
                    <Nav.Link >Mis Declaraciones</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              )}

              {isAuthenticated && (

                <Nav.Item>
                  <LinkContainer to="/herramientas">
                    <Nav.Link >Herramientas</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              )}

            </Nav>

            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={login}
                    style={{ backgroundColor: '#1C68A7' }}
                  >
                    Ingresar
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <Dropdown className="nav-item" >
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    <FontAwesomeIcon icon="user-circle" className="mr-3" />

                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Header  >
                      {username} <br/>
                      {email}
                    </Dropdown.Header >
                    <Dropdown.Item

                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" />
                        Perfil
                      </Dropdown.Item>
                    <Dropdown.Item
                      id="qsLogoutBtn"
                      onClick={logout}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" />
                        Cerrar Sesión
                      </Dropdown.Item>
                  </Dropdown.Menu>

                </Dropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={login}

                    style={{ backgroundColor: '#1C68A7' }}
                  >
                    Iniciar Sesión
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar

              >

                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <NavLink
                    to="/declaraciones"
                    activeClassName="router-link-exact-active"
                  >
                    Perfil
                  </NavLink>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <NavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={logout}
                  >
                    Cerrar Sesión
                  </NavLink>
                </NavItem>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>


    </>
  )
}

export default Navigation;