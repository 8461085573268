import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { Jumbotron } from 'react-bootstrap';
import { useHistory } from 'react-router';


const Inicio: React.FC = (props: any) => {

    const username = firebase.auth().currentUser?.displayName

    const history = useHistory();

    // useEffect(() => {

    //     const currentUser = firebase.auth().currentUser;
    //     if (!currentUser) {
    //         history.push("/SignIn");
    //     }
    //     const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
    //         (user) => {
    //             if (!user) {
    //                 history.push("/SignIn");
    //             }
    //         }
    //     )

    //     return () => {
    //         unregisterAuthObserver();
    //     }

    // })


    return (
        <React.Fragment>

            <Jumbotron>
                <h1>Bienvenido a SimplyPro</h1>
                <p>La manera más sencilla de hacer tu declarión de renta en Colombia.</p>


            </Jumbotron>
            

           

        </React.Fragment>

    );
}

export default Inicio;



