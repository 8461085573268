import firebase from "firebase";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument, useDocumentOnce } from "react-firebase-hooks/firestore";
import { useHistory, useParams } from "react-router-dom";
import { DeclaracionDeRenta } from "../../dbTypes/Declaracione";
import Stepper, { StepsDocuemnt } from "../widgets/Stepper/Stepper";



interface RouterParams {
    declaracionId: string
}

interface ResidenciaFiscalSteps extends StepsDocuemnt  {
    step1:boolean|undefined
    step2:boolean|undefined
    step3:boolean|undefined

}

const Preparacion: React.FC = (props: any) => {

    const { declaracionId } = useParams<RouterParams>();

    const [user, loadingAuth, errorAuth] = useAuthState(firebase.auth());

    let history = useHistory();

    const configPath = "/preguntas/declaracion-2019/preparacion/s01";
    const dataPath = `/usuarios/${user?.uid}/declaraciones/${declaracionId}/preparacion/s01`;
    const [declaracionDocSnap, loadingDeclaracion] = useDocument(firebase.firestore().doc(`/usuarios/${user?.uid}/declaraciones/${declaracionId}`));

    const resultCalculation = (doc:ResidenciaFiscalSteps)=>{
        return !!doc.step1 || (!!!doc.step2 && !!doc.step3);
    }

    if(!loadingDeclaracion && declaracionDocSnap){
        const declaracion = declaracionDocSnap.data() as DeclaracionDeRenta;
        if(declaracion.estado ==="no-iniciada"){
            declaracionDocSnap.ref.update({
                "estado": "preparacion-iniciada"
            })
        }
    }

    const done = async  (value:boolean) =>{ 
        if(!loadingDeclaracion && declaracionDocSnap){
            const declaracion = declaracionDocSnap.data() as DeclaracionDeRenta;
            if(value){
                await declaracionDocSnap.ref.update({
                    "estado": "preparacion-completa"
                });
                history.push(`/declaraciones/${declaracionId}`);
            } else {
                await declaracionDocSnap.ref.update({
                    "estado": "no-residente"
                });
            }
        }
        
        
        
    
    }

    let  pendiente = false;
    let residente = true;
    if(declaracionDocSnap){
        const declaracion = declaracionDocSnap.data()as DeclaracionDeRenta;
        pendiente = declaracion.estado ==="no-iniciada" || declaracion.estado ==="preparacion-iniciada";
        residente = !(declaracion.estado === "no-residente") 
    }

    if(declaracionDocSnap && !pendiente && residente){
        history.push(`/declaraciones/${declaracionId}`);
    }


    return (
        <>
        {loadingDeclaracion  && <h3>Cargando...</h3>}
        <div>
            <h3>Antes de comenzar</h3>
            { pendiente 
                ? 
                    <Stepper<ResidenciaFiscalSteps> configPath={configPath} dataPath={dataPath} done={done} resultCalculation={resultCalculation}> </Stepper>
                : residente  
                    ? <p>Redireccionando..</p>
                    : <p>No eres residente fiscal en Colombia. ver mas información acá...</p>

                 
            }
            
        </div>

        </>
    
    )
}


export default Preparacion;