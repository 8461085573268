import firebase from 'firebase';
import { ServiceAccountCredentials } from 'google-spreadsheet';
import React, { FormEvent, FormEventHandler, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

interface ExportArgs {
    sheedId: string
    botID:string
    declaracionId: string
}

interface ImportArgs {
    sheedId: string
    botID:string
    declaracionId: string,
    titulo: string
}


const Herramientas: React.FC = (props: any) => {
    const [bots] = useCollectionDataOnce<ServiceAccountCredentials & { id: string }>(firebase.firestore().collection(`/bots`))
    const [selectedBotIdExport, setSelectedBotIdExport] = useState<string | undefined>();
    const [selectedBotIdImport, setSelectedBotIdImport] = useState<string | undefined>();

    const [exportDocs] = useCollectionDataOnce<{ id: string, uuid: string }>(firebase.firestore().collection(`/exportDocs`))
    const [selectedExportDocId, setselectedExportBotId] = useState<string | undefined>();

    const [importDocs] = useCollectionDataOnce<{ id: string, uuid: string }>(firebase.firestore().collection(`/importDocs`))
    const [selectedImportDocId, setselectedImportDocId] = useState<string | undefined>();

    const [importID, setimportID] = useState<string >("");
    const [importTitle, setimporTitle] = useState<string >("");

    const [message, setMessage] = useState<string >("-");

    const onImportTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const newValue = e.currentTarget.value;
        setimporTitle(newValue)
    }

    const onImportIdChanged = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const newValue = e.currentTarget.value;
        setimportID(newValue)
    }

    const onBotSelectionChangedExport = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedBotId = event.currentTarget.value;
        setSelectedBotIdExport(selectedBotId);
    }

    const onDocSelectionChangedExport = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedExportBotId = event.currentTarget.value;
        setselectedExportBotId(selectedExportBotId);
    }


    const onBotSelectionChangedImport = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedBotId = event.currentTarget.value;
        setSelectedBotIdImport(selectedBotId);
    }

    const onDocSelectionChangedImport = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedImportDocId = event.currentTarget.value;
        setselectedImportDocId(selectedImportDocId);
    }

    const onSumbitExport : FormEventHandler= (event: FormEvent) =>{
        event.preventDefault();
        setMessage("sending...")
        const exportCall = firebase.functions().httpsCallable("ExportCallable");

        
        if(selectedBotIdExport && selectedExportDocId){
            const exportArgs : ExportArgs = {
                botID: selectedBotIdExport,
                sheedId: selectedExportDocId,
                declaracionId: "20019III"
            }

            exportCall(exportArgs).then( result =>{
                setMessage("Exportación exitosa.")
            }).catch(error=>{
                
                setMessage(""+error);           
            });
        }
        
        
    }

    const onSumbitImport : FormEventHandler= (event: FormEvent) =>{
        event.preventDefault();
        setMessage("sending...")
        const importCall = firebase.functions().httpsCallable("ImportCallable");

        if(selectedBotIdImport && selectedImportDocId){
            const importArgs : ImportArgs = {
                botID: selectedBotIdImport,
                sheedId: selectedImportDocId,
                declaracionId: importID,
                titulo: importTitle
            }

            importCall(importArgs).then( result =>{
                setMessage("Importación exitosa.")
            }).catch(error=>{
                
                setMessage(""+error);           
            });
        }
    }

    if(bots && !selectedBotIdExport){
        setSelectedBotIdExport(bots[0].id)
    }

    if(exportDocs && !selectedExportDocId){
        setselectedExportBotId(exportDocs[0].uuid)
    }

    if(bots && !selectedBotIdImport){
        setSelectedBotIdImport(bots[0].id)
    }

    if(importDocs && !selectedImportDocId){
        setselectedImportDocId(importDocs[0].uuid)
    }



    return (<div><h1>Herramientas</h1>
        <h2>Exportar</h2>
        {bots && exportDocs &&
            
            <Form onSubmit={onSumbitExport}>
                <Form.Group as={Row} controlId="botExport" >
                    <Form.Label column sm="6">
                        Bot
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control
                            style={{ width: "120px", }}
                            as="select"
                            value={selectedBotIdExport}
                            className="my-1 mr-sm-2"
                            ref={(el: any) => el?.style.setProperty('margin', "0px ", 'important')}
                            onChange={onBotSelectionChangedExport}
                            custom>
                            {bots.map(({ id, client_email }) => <option value={id}>{`${id} - ${client_email}`}</option>)}

                        </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="docExport" >
                    <Form.Label column sm="6">
                        Export Doc
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control
                            style={{ width: "120px", }}
                            as="select"
                            value={selectedExportDocId}
                            className="my-1 mr-sm-2"
                            ref={(el: any) => el?.style.setProperty('margin', "0px ", 'important')}
                            onChange={onDocSelectionChangedExport}
                            custom>
                            {exportDocs.map(({ id, uuid }) => <option value={uuid}>{`${id} - ${uuid}`}</option>)}

                        </Form.Control>
                    </Col>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Exportar
                </Button>
            </Form>

            
        }

        <div className="center"> {message}</div>
        <h2>Importar</h2>
        {bots && importDocs &&
            <Form onSubmit={onSumbitImport}>
                <Form.Group as={Row} controlId="botImport" >
                    <Form.Label column sm="6">
                        Bot
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control
                            style={{ width: "120px", }}
                            as="select"
                            value={selectedBotIdImport}
                            className="my-1 mr-sm-2"
                            ref={(el: any) => el?.style.setProperty('margin', "0px ", 'important')}
                            onChange={onBotSelectionChangedImport}
                            custom>
                            {bots.map(({ id, client_email }) => <option value={id}>{`${id} - ${client_email}`}</option>)}

                        </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="docImport" >
                    <Form.Label column sm="6">
                        Import Doc
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control
                            style={{ width: "120px", }}
                            as="select"
                            value={selectedImportDocId}
                            className="my-1 mr-sm-2"
                            ref={(el: any) => el?.style.setProperty('margin', "0px ", 'important')}
                            onChange={onDocSelectionChangedImport}
                            custom>
                            {importDocs.map(({ id, uuid }) => <option value={uuid}>{`${id} - ${uuid}`}</option>)}

                        </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="importID" >
                    <Form.Label column sm="6">
                        Id
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control
                            type="text"
                            required
                            value={importID}
                            onChange={onImportIdChanged}>

                        </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="importTitle" >
                    <Form.Label column sm="6">
                        Title
                    </Form.Label>
                    <Col sm="6">
                        <Form.Control
                            type="text"
                            required
                            value={importTitle}
                            onChange={onImportTitleChanged}>

                        </Form.Control>
                    </Col>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Importar
                </Button>
            </Form>

            
        }



    </div>)
};

export default Herramientas;