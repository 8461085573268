import React, { useState } from "react"
import { Button, Form } from "react-bootstrap"

export interface SiNoNumeroValue {
    siNo: boolean|undefined
    numero: number|null
}
interface SiNoNumeroProperties{
    value:SiNoNumeroValue|undefined
    onValueChanged: (newValue:SiNoNumeroValue) => void
}

declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const SiNoNumero : React.FC<SiNoNumeroProperties> = ({value,onValueChanged}) =>{

    const [internalValue, setInternalValue] = useState<SiNoNumeroValue|undefined>(value);

    const onsiNoChanged = (e: React.MouseEvent, value: boolean) =>{
        const numero = value? 0: null;
        const newValue: SiNoNumeroValue = { siNo: value, numero:numero }
        onValueChanged(newValue)
        setInternalValue(newValue)
    }

    const onNumberChanged = (e: React.ChangeEvent<FormControlElement>) =>{
        const numero = Number.parseInt(e.currentTarget.value) ;
        const newValue: SiNoNumeroValue = { siNo: internalValue?.siNo, numero:numero }
        onValueChanged(newValue);
        setInternalValue(newValue)

    }


    return (
    <div className="sino-numero-container d-flex">
        <div className="si-no-container">
            <Button variant={internalValue?.siNo === true ? "success" : "outline-success"} onClick={  e =>  onsiNoChanged(e,true) }>Si</Button>
            <Button variant={internalValue?.siNo === false ? "danger" : "outline-danger"} onClick={  e =>  onsiNoChanged(e,false) }>No</Button>
        </div>

        <Form.Control type="number"  disabled={!internalValue?.siNo} value={internalValue?.numero ? internalValue?.numero: "" } onChange={onNumberChanged} style={{ width: "120px", marginLeft:"10px" }} />

    </div> 
    )
}


export default SiNoNumero;