import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { Button, Col, Form, Row } from "react-bootstrap";
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';


const calculoFinal2 = firebase.functions().httpsCallable('CalculoFinal2');
const calculoFinal2021 = firebase.functions().httpsCallable('CalculoFinal2021');

type TipoElementoCalculos = "seccion"|"subseccion"|"casilla"



interface ElementoCalculos {
    texto: string;
    tipo : TipoElementoCalculos
} 

interface Seccion extends ElementoCalculos{
    tipo: "seccion"
    hijos: Array<Subseccion|Casilla>;
}
interface  Subseccion extends ElementoCalculos {
    tipo: "subseccion"
    hijos: Array<Casilla>
}

interface Casilla extends ElementoCalculos {
    tipo: "casilla"
    numeroCasilla: string
    valor: number
}

function esCasilla(x: ElementoCalculos): x is Casilla {
    return x.tipo === "casilla";
}

function esSubseccion(x: ElementoCalculos): x is Subseccion {
    return x.tipo === "subseccion";
}


interface Calculos {
    secciones: Seccion[];
    
}

const   base64toBlob = (dataURI:string) =>{
    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
}

const Calculos: React.FC<{ declaracionId: string}> = ({ declaracionId }) => {
    const [calculos, setCalculos] = useState<Calculos|undefined>()

    const [error, setError]= useState<string|undefined>()

    const  numberWithCommas =  (x:number) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const renderCasilla = (casilla:Casilla) =>{
        return(       
            <Row className="mb-3">

                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{casilla.numeroCasilla}. {casilla.texto} </Form.Label>
                    <Form.Control type="text" value={numberWithCommas(casilla.valor)} contentEditable={false}  />
                </Form.Group>
                <Form.Label column lg={2}>
                    
                </Form.Label>
                <Col>
                    
                </Col>
            </Row>
        )
    }

    const renderSubseccionOCasilla = (elemento : Subseccion|Casilla) => {
        if(esSubseccion(elemento)){
            return (<React.Fragment>

                <Col sm="12">
                    <h5>{elemento.texto}</h5>
                </Col>
        
                {elemento.hijos.map(renderCasilla)}
        
                </React.Fragment>)
        } else if( esCasilla(elemento)){
            return renderCasilla(elemento)
        }
    }

    const renderSeccion = (seccion : Seccion) => {
        return     <React.Fragment>

        <Col sm="12">
            <h3>{seccion.texto}</h3>
        </Col>

        {seccion.hijos.map(renderSubseccionOCasilla)}

        </React.Fragment>
    }

    const renderCalculos = (calculos: Calculos) =>{
        return (<Form>
            {calculos.secciones.map(renderSeccion)}

        </Form>)
    }


    useEffect(()=>{
        let calculosFunc = declaracionId.includes('2021') ? calculoFinal2021 : calculoFinal2;

        calculosFunc({ declaracionId: declaracionId })
        .then((result ) => {

            const calculos = (result.data as Calculos);

            setCalculos(calculos);
        }).catch(e=>{
            
            console.error(e);
        });

    }, [declaracionId])



    return (<div>
        {!calculos && !error&& "Calculando..." }
        {error && <p>{error}</p>}
        {calculos && renderCalculos(calculos)}

        {/* <Button onClick={descargarPDF}>Descargar PDF</Button> */}
    </div>)
}


export default Calculos;