import React from "react";
import { Form } from "react-bootstrap";
import { opcion } from "simplypro-db";

type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export interface OpcionUnicaProps {
    valor: string|number|undefined;
    onValueChanged: React.ChangeEventHandler<FormControlElement>;
    opciones: opcion[]

}

const OpcionUnica : React.FC<OpcionUnicaProps> = ({valor, onValueChanged, opciones})=>{
   return (
    <Form.Control
        style={{ width: "120px", }}
        as="select"
        value={valor}
        className="my-1 mr-sm-2"
        ref={(el: any) => el?.style.setProperty('margin', "0px ", 'important')}
        onChange={onValueChanged}
        custom>
            <option disabled selected > -Seleccione- </option>
        {opciones.map(({id,texto}) => <option value={id}>{texto}</option>)}

    </Form.Control>)
}


export default OpcionUnica;