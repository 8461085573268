import firebase, { firestore } from "firebase";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { useCollection, useCollectionData, useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { Respuesta, SubpreguntaTablaEstatica, TablaEstatica } from "simplypro-db";
import cellEditFactory from 'react-bootstrap-table2-editor';
import InfoToolTip from "./InfoTooltip";

type DocumenDataQuerySnap = firestore.QuerySnapshot<firestore.DocumentData>;
export interface TablaProps {
    pregunta: TablaEstatica<"número">
    declaracionId: string
    userId?:string
    className?:string
}

const Tabla : React.FC<TablaProps> = ({pregunta, declaracionId, userId, className})=>{

    const numberFormat = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', maximumFractionDigits: 0, minimumFractionDigits: 0 });
    const tableColumns = pregunta.columnas?.map(c => {
        
        const result = { dataField: c.id, text: c.titulo,  editable:(c.tipoDeColumna !== "estática")} as ColumnDescription;

        if (c.tipoDeColumna === "numero") {
            result.align = "right";
            result.formatter = (cell, row) => {
                const formatedNumber = numberFormat.format(cell);
                return (<>{formatedNumber}</>)
            }
        }

        result.headerStyle = { width: c.ancho+"%", textAlign: 'center' , backgroundColor:"rgb(222,230,239)"}
        return result;
    });

    const [preguntas] =  useCollectionData<SubpreguntaTablaEstatica>(firebase.firestore().collection(`/definiciones/${declaracionId}/preguntas`).where('preguntaMadre', '==', `${pregunta.id}`));
    const [respuestas, cargandoRespuestas, errorCargandoRespuestas] =  useCollection(firebase.firestore().collection(`/usuarios/${userId}/declaraciones/${declaracionId}/respuestas/`));


    const getRespuestaWithSnapForPregunta = <R extends Respuesta>(p:SubpreguntaTablaEstatica, respuestas: DocumenDataQuerySnap) =>{
        const snapValueMap = respuestas.docs.map(d=> ({respuestaSnap: d , respuesta:(d.data() as R)}));
        const result =  snapValueMap.find(({respuestaSnap,respuesta}) =>{
            return respuesta.id === p.id;
        });

        if(!result){
            throw Error("Respuesta no encontrada");
        }
        return result;
    };


    const tableEntries = preguntas?.map(p=>{
        const result = {} as any;
        result[p.columnaTexto] = p.texto;
        result["id"] = p.id;
        for(const cId in p.columnasAdicionales){
            const valor = p.columnasAdicionales[cId];
            result[cId] = valor;
        }
        if(respuestas){
            const resWithSnap = getRespuestaWithSnapForPregunta(p, respuestas);
            
            result[p.columnaValor] = resWithSnap.respuesta.valor ? resWithSnap.respuesta.valor : 0;
            result.respuesta = resWithSnap.respuesta;
            result.respuestaSnap = resWithSnap.respuestaSnap;
        }
        return result;
    })

    const rowStyle = (row:any, rowIndex:number) => {
        row.index = rowIndex;
        const style:any = {};
        if (rowIndex % 2 === 0) {
          style.backgroundColor = 'transparent';
        } else {
          style.backgroundColor = 'rgba(241, 241, 241, 1)';
        }
        style.borderTop = 'none';
    
        return style;
      }

      const onCellSave = (oldValue: any, newValue: string, row: any, column: ColumnDescription<any> & any) => {
        row.valor= Number.parseInt(newValue);
        const respuesta = row.respuesta;
        respuesta.valor = row.valor;
        row.respuestaSnap.ref.set(respuesta);

    }




    return (<>
    
    <Form.Group className={className} as={Row} >
                <Form.Label column sm="12">
                    <h5>{pregunta.texto} <InfoToolTip id={pregunta.id} ayuda={pregunta.ayuda} /></h5> &nbsp; 

                </Form.Label>
                <Col sm="5">

                </Col>

            </Form.Group>
            <Col sm="12" className={className} >

                {tableColumns && tableEntries &&

                    <BootstrapTable keyField='id' data={tableEntries} columns={tableColumns} 
                    rowStyle={rowStyle}
                    cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: onCellSave })} />}

            </Col>

            <Col sm="12" className={className} >
                <div className="d-flex justify-content-end" >
                    {/* Total: {numberFormat.format(total)} */}
                </div>
            </Col>
    </>)

}

export default Tabla;


