import firebase from "firebase";
import React, { useState, PropsWithChildren} from "react";
import { Button, Carousel } from "react-bootstrap";
import { useCollectionOnce, useDocument } from "react-firebase-hooks/firestore";
import StepNavigation, { IStep } from "./StepNavigation";
import { findIconDefinition, icon, IconDefinition, IconLookup, library } from '@fortawesome/fontawesome-svg-core';
import { Step } from "../../../dbTypes/Step";
import SiNo from "../SiNo";
import TableInput from "../TableInput";



export interface StepperProperties<T extends StepsDocuemnt>{
    configPath: string
    dataPath:string
    done: (value:boolean) => void
    resultCalculation: (doc:T)=> boolean
}


export interface StepsDocuemnt extends firebase.firestore.DocumentData {
    result:boolean;
}


function Stepper<T extends StepsDocuemnt>({configPath, dataPath, done, resultCalculation}:PropsWithChildren<StepperProperties<T>>)  {

    const [index, setIndex] = useState(0);

    const [stepsConfigCollection, loadingConfig, configError] = useCollectionOnce(firebase.firestore().collection(`${configPath}/steps`).orderBy('index', 'asc'));

    const [dataDocumentSnap, loadingData, dataError] = useDocument(firebase.firestore().doc(dataPath));

    const handleSelect = (selectedIndex: number, e: any) => {
        setIndex(selectedIndex);
    };

    const getDataDocuemnt = () => {
        if (!loadingData && dataDocumentSnap) {
            const doc = dataDocumentSnap.data()
            if (doc) {
                if(!doc.hasOwnProperty("result")){
                    doc["result"] = true;
                }
                return doc;
            }
        }
        return { result: true}
    }
    const dataDocument = getDataDocuemnt();

    const handleStepInput = async (key:string, value:any )=>{
        if(value !== undefined){
            dataDocument[key] = value;
            dataDocument.result = resultCalculation(dataDocument as T);

            dataDocumentSnap?.ref.set(dataDocument);
            setIndex(index +1)
        }
        
    }



    let stepSymbols : IStep[]|undefined = undefined;
    let carouselItems : JSX.Element[]|undefined = undefined;

    if(!loadingConfig){
        let stepConfigs = stepsConfigCollection?.docs.map(d=>{
            const step = d.data() as Step
            step.id= d.id
            return step;
        });

        stepConfigs = stepConfigs?.filter((config, index, configs)=>{
            let showStep=false;
            
            if(config.stepType === "question"){
                let shouldHide = false;
                if(config.hideConditions){
                    for(const key in config.hideConditions){
                        const value = config.hideConditions[key];
                        const dataValue = dataDocument[key];
                        shouldHide = shouldHide || (value === dataValue)
                    }
                }
    
                let shouldShow = false;
                if(config.showConditions){
                    for(const key in config.showConditions){
                        const value = config.showConditions[key];
                        const dataValue = dataDocument[key];
                        shouldShow = shouldShow || (value === dataValue)
                    }
                }  
                
                showStep= (!!!config.hideConditions &&  !!!config.showConditions) || (!!config.showConditions && shouldShow) || (!!config.hideConditions && !shouldHide)

            } else if( config.stepType=== "finalInfo"){
                if(config.finalCondition === dataDocument.result){
                    showStep = true;
                }


            }



            return showStep;

        })


        stepSymbols = stepConfigs?.map(s => {

            const iconLookup: IconLookup = s.faIcon
            const iconconDefinition: IconDefinition = findIconDefinition(iconLookup)

            let stepDefinition : IStep = {
                title: s.title,
                icon: iconconDefinition
            }
            return stepDefinition;

        });

        

        carouselItems = stepConfigs?.map(s => {

            let item = (
                <Carousel.Item>
                    <div className="carousel-item-content " style={{zIndex:2, position:"relative"}} >
                        <h3>{s.title}</h3>
                        <div className="step-body" dangerouslySetInnerHTML={{__html: s.text}}></div>
                        {s.stepType === "question" && <SiNo value={dataDocument[s.id]} onValueChanged={(newValue)=> handleStepInput(s.id, newValue)} /> }
                        {s.stepType === "finalInfo" && <Button variant="success" onClick={_ =>done(true)} >Continuar</Button> }
                    </div>
                </Carousel.Item>

            )
            return item;
        });

    }


    return (
        <div>
            {loadingConfig && "loading..."}
            {!loadingConfig &&
            (                <>
                <StepNavigation activeColor="#120D4B" completeColor="#1662A0" steps={stepSymbols} activeStep={index} />
                <Carousel activeIndex={index} onSelect={handleSelect} interval={null} indicators={false} wrap={false} style={{height:"250px"}}>
                    {carouselItems}
                </Carousel>
                </>
            )
            }


        </div>
    
    )
}


export default Stepper;