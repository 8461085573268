import React from "react";
import { Form } from "react-bootstrap";


declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface Props {
    value?:string;
    onValueChanged: (newValue:string) => void
    
}

const TextoLibre : React.FC<Props> = ({onValueChanged, value})=>{
    const onTextChanged = (e: React.ChangeEvent<FormControlElement>) =>{
        const newValue = e.currentTarget.value;
        onValueChanged(newValue);
    }

    return <Form.Control type="text"  value={value} onChange={onTextChanged}  />
}

export default TextoLibre;