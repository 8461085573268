import React, { useEffect, useReducer, useState } from "react";
import { opcion, ValorOpcionMultiple } from "simplypro-db";
import Select, { ActionMeta } from 'react-select';
import { Form, Row } from "react-bootstrap";
import InfoToolTip from "./InfoTooltip";
import { valueFocusAriaMessage } from "react-select/src/accessibility";



type Option = { label: string; value: string }

export type OnSelectionChanged = (newSelection: string[],  valores: {[id:string]: any} | null) => void;
export interface OpcionMultipleProps {
    valor?: ValorOpcionMultiple
    onSelectionChanged: OnSelectionChanged;
    opciones: opcion[]
    tieneValores: boolean;

}

const opcionToCheckBox = (opcion: opcion, isChecked: boolean, valor: number|undefined , disabled: boolean, onCheckChange: React.ChangeEventHandler<HTMLInputElement>, onValueChanged: React.ChangeEventHandler<HTMLInputElement>, tieneValor:boolean) => {

    return (
        <div className=" d-flex justify-content-end  contenedor-opcion">
            <Form.Label  htmlFor={`${opcion.id}`}  >
                {opcion.texto}
            </Form.Label>
            <Form.Check
                className="opcion-check"
                inline
                id={`${opcion.id}`}
                name={`${opcion.id}`}
                disabled={disabled}
                type="checkbox"
                checked={isChecked}
                onChange={onCheckChange}
            />

            <InfoToolTip className="opcion-help" id={`info-${opcion.id}`} ayuda={opcion.ayuda ? opcion.ayuda : ""} />
            
            {tieneValor && <Form.Control type="number"
                id={`v-${opcion.id}`}  
                disabled={!isChecked} 
                value={valor} 
                onChange={onValueChanged}
                style={{ width: "120px", marginLeft:"10px" }} />}


        </div>



    )
}




const OpcionMultiple: React.FC<OpcionMultipleProps> = ({ valor, onSelectionChanged, opciones, tieneValores }: OpcionMultipleProps) => {

    const isChecked = (opcion: opcion) => valor ? valor.seleccion.findIndex(v => v === opcion.id) >= 0 : false;
    const initState = valor ? opciones.reduce<Record<string, boolean>>((acc, o) => { acc[o.id] = isChecked(o); return acc }, {}) : {};
    const [checkStates, setCheckStates] = useState(initState);
    const [valuesState, setValuesState] = useState(valor?.valores || {})

    const [exclusiveActive, setExclusiveActive] = useState(false);

    
    useEffect( ()=>{
        opciones.forEach(o=> {
            if(o.exclusive && checkStates[o.id]){
                setExclusiveActive(true);
            }
        })
    }, [opciones, checkStates])


    const onCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const { id } = event.currentTarget;
        const opcion = opciones.find(o => o.id === id);

        const newCheckState = !checkStates[id];
        const newCheckStates = {...checkStates}

        //If exclusive and set checked uncheck all  others and disable 
        if (opcion?.exclusive && newCheckState) {
            for (const opcionId in newCheckStates) {
                newCheckStates[opcionId]= false;
            }
            setExclusiveActive(true);
        }


        if (opcion?.exclusive && !newCheckState) {
            setExclusiveActive(false);
        }


        newCheckStates[id] = newCheckState;
        const newValue = [];
        for (const id in newCheckStates) {
            if (newCheckStates[id]) {
                newValue.push(id);
            }
        }
        onSelectionChanged(newValue, valuesState);

        setCheckStates(newCheckStates);


    }


    const onNumericValueChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>{
        const { id } = event.currentTarget;
        const valueKey = id.substring(2);
        const numberValue = Number.parseInt(event.currentTarget.value);

        const newValueState = {...valuesState}
        newValueState[valueKey] = numberValue;
        

        const seleccion = [];
        for (const id in checkStates) {
            if (checkStates[id]) {
                seleccion.push(id);
            }
        }

        onSelectionChanged(seleccion, valuesState);

        setValuesState(newValueState)

    }



    return (
        <div className="my-1 mr-sm-2 seleccion-multiple" >
            {opciones.map(o => opcionToCheckBox(o, checkStates[o.id], valuesState[o.id], (exclusiveActive && !o.exclusive), onCheckboxChange,onNumericValueChange , tieneValores ))}
        </div>

    )
}


export default OpcionMultiple;